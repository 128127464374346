const MM = MM || {};

// ------------------------------------------------------------
// - ▼ グローバルでよく使う変数
// ------------------------------------------------------------
MM.vars = {
  $body    : $('body'),
  $window  : $(window),
  userAgent: '',
  herovisualStatic: [
    '/_materials/images/top/hero_visual_parts_a.png',
    '/_materials/images/top/hero_visual_parts_b.png',
    '/_materials/images/top/hero_visual_parts_b_sp.png',
    '/_materials/images/top/hero_visual_01_a.png',
    '/_materials/images/top/hero_visual_01_b.png',
    '/_materials/images/top/hero_visual_01_a_sp.png',
    '/_materials/images/top/hero_visual_01_b_sp.png',
    '/_materials/images/top/hero_visual_02_a.png',
    '/_materials/images/top/hero_visual_02_b.png',
    '/_materials/images/top/hero_visual_02_a_sp.png',
    '/_materials/images/top/hero_visual_02_b_sp.png',
    '/_materials/images/top/hero_visual_03_a.png',
    '/_materials/images/top/hero_visual_03_b.png',
    '/_materials/images/top/hero_visual_03_a_sp.png',
    '/_materials/images/top/hero_visual_03_b_sp.png',
    '/_materials/images/top/hero_lead_1.png',
    '/_materials/images/top/hero_lead_2.png',
    '/_materials/images/top/hero_lead_3.png',
    '/_materials/images/top/hero_lead_4.png',
    '/_materials/images/top/hero_lead_5_sp.png',
    '/_materials/images/top/hero_lead_6_sp.png',
    '/_materials/images/top/hero_lead_7_sp.png',
    '/_materials/images/top/hero_lead_jp.png'
  ],
  herovisualImages: [
    '/_materials/images/top/hero_frame_01.jpg',
    '/_materials/images/top/hero_frame_02.jpg',
    '/_materials/images/top/hero_frame_03.jpg',
    '/_materials/images/top/hero_frame_04.jpg',
    '/_materials/images/top/hero_frame_05.jpg',
    '/_materials/images/top/hero_frame_06.jpg',
    '/_materials/images/top/hero_frame_07.jpg',
    '/_materials/images/top/hero_frame_08.jpg',
    '/_materials/images/top/hero_frame_09.jpg',
    '/_materials/images/top/hero_frame_10.jpg',
    '/_materials/images/top/hero_frame_11.jpg',
    '/_materials/images/top/hero_frame_12.jpg',
    '/_materials/images/top/hero_frame_13.jpg'
  ]
};


// ------------------------------------------------------------
// - ▼ 全体で使い回すモジュール
// ------------------------------------------------------------
MM.init = {

  execute: function() {
    this.constructor();
    this.navigationSwitch.fn();
    this.navigationScroll.fn();
    this.navigationAchordion.fn();
    this.sitemapAchordion.fn();
    this.resize();
    this.scroll();
    this.matchMedia();
    this.contentsSchreen.fn();
    // this.featureSlider.fn();
    this.pjax();
    this.pageTop();
  },

  // ▽ 最初に実行すべき関数
  // ----------------------------------------
  constructor: function() {
    // 画像のプリロード
    MM.vars.herovisualImages.forEach(function(value) {
      var $img = $('<img>').attr('src', value );
      // console.log($img);
      console.log(value + 'をプリロードしました。');
    });
  },


  // windowサイズを取得する
  getWindowWidth: function() {
    return window.innerWidth || document.documentElement.clientWidth;
  },

  // かビゲーション開閉
  navigationSwitch: {
    $btn: $('#l_header_menu_btn'),
    $target: $('#l_navigation'),
    fn: function() {
      var _this = this;

      // メニューボタンをクリック時に開閉
      this.$btn.on('click', function() {
        _this.toggle();
      });

      // ナビゲーションの外側（グレーのエリア）をクリック時に閉じる
      $('.l_navigation').click(function(event) {
        if($(event.target).find('.l_navigation_container').length) {
          _this.close();
        }
      });
    },
    timer: '',
    open: function() {
      if(MM.vars.$body.hasClass('VIEW_NAVIGATION')) return;
      MM.vars.$body.addClass('VIEW_NAVIGATION');
      MM.init.bodyFixed.set(); // bodyのスクロールを固定
      MM.init.navigationScroll.ps.update();
    },
    close: function() {
      if(!MM.vars.$body.hasClass('VIEW_NAVIGATION') || this.timer) return;
      MM.vars.$body.addClass('NAVIGATION_CLOSE_ANIMATION');
      this.timer = setTimeout(() => {

        MM.init.bodyFixed.unset(); // bodyのスクロールを固定
        MM.vars.$body.removeClass('VIEW_NAVIGATION NAVIGATION_CLOSE_ANIMATION');

        this.timer = 0;
      }, 300);
    },
    toggle: function() {
      if( MM.vars.$body.hasClass('VIEW_NAVIGATION') ) {
        this.close();
      } else {
        this.open();
      }
    }
  },

  // ナビゲーションのスクロール
  navigationScroll: {
    ps: '',
    fn: function() {
      this.ps = new PerfectScrollbar('#l_navigation_scroller', {
        wheelPropagation: true,
      });
    },
  },

  pageTop: function() {
    var btn = $('#js_l_page_top');

    btn.on('click', function(){
      $('body').velocity('scroll', { duration: 300, easing: 'ease'});
    });
  },


  // コンテンツ内のアンカー
  contentsAnchor: function() {
    var $main = $('.l_main');
    var $anchor = $main.find('a[href^="#"]');
    $anchor.on('click', function(){
      console.log($(this));
      var href = $(this).attr("href");
      var $target = $(href === "#" || href === "" ? 'html' : href);

      $target.velocity("scroll", { duration: 500, easing: "ease" });
      return false;
    });
  },


  // ナビゲーションのアコーディオン（独自）
  navigationAchordion: {
    $btns: $('.js_l_navigation_main_header'),
    $bodys: $('.js_l_navigation_main_body'),
    $activeClassName: 'is_active',
    fn: function() {
      var _this = this;
      this.$btns.on('click', function(){
        if(MM.init.getWindowWidth() <= 768) {
          _this.toggle($(this));
        }
      });
    },
    open: function($btn) {
      var _this = this;
      if(!$btn instanceof jQuery || $btn.hasClass('is_animation')) return;
      if(!$btn.hasClass( _this.$activeClassName )) {
        $btn.addClass( _this.$activeClassName + ' is_animation' );
        $btn.next().velocity('finish', true).velocity('slideDown', {
          duration: 200,
          complete:function(elm) {
            $btn.removeClass('is_animation');
            MM.init.navigationScroll.ps.update();
          }
        });
      }
    },
    close: function($btn) {
      var _this = this;
      if(!$btn instanceof jQuery || $btn.hasClass('is_animation')) return;
      if($btn.hasClass( _this.$activeClassName )) {
        $btn.removeClass( _this.$activeClassName ).addClass('is_animation');
        $btn.next().velocity('finish', true).velocity('slideUp', {
          duration: 200,
          complete:function(elm) {
            $btn.removeClass('is_animation');
            MM.init.navigationScroll.ps.update();
          }
        });
      }
    },
    toggle: function($btn) {
      var _this = this;
      if(!$btn instanceof jQuery) return;
      if($btn.hasClass( _this.$activeClassName )) {
        this.close($btn);
      } else {
        this.open($btn);
      }
    },
    clear: function() {
      console.log('a');
      this.$btns.removeClass(  this.$activeClassName );
      this.$bodys.css({'display': ''});
    }
  },

  // ▽ メニューを表示する
  // ----------------------------------------
  menu: {
    $btn: $('.l_header_menu_btn'),
    set: function() {
      var _this = this;
      this.$btn.on('click', () => {
        this.open();
      });
    },
    toggle: function() {

    },
    open: function() {
      MM.init.bodyFixed.set();
    },
    close: function() {
      MM.init.bodyFixed.unset();
    }
  },


  // Footerのサイトマップアコーディオン（独自）
  sitemapAchordion: {
    $btns: $('.js_l_sitemap_achordion_btn'),
    $bodys: $('.js_l_sitemap_achordion_body'),
    $activeClassName: 'is_active',
    fn: function() {
      var _this = this;
      this.$btns.on('click', function(){
        if(MM.init.getWindowWidth() <= 768) {
          _this.toggle($(this));
        }
      });
    },
    open: function($btn) {
      var _this = this;
      if(!$btn instanceof jQuery || $btn.hasClass('is_animation')) return;
      if(!$btn.hasClass( _this.$activeClassName )) {
        $btn.addClass( _this.$activeClassName + ' is_animation' );
        $btn.next().velocity('finish', true).velocity('slideDown', {
          duration: 200,
          complete:function(elm) {
            $btn.removeClass('is_animation');
          }
        });
      }
    },
    close: function($btn) {
      var _this = this;
      if(!$btn instanceof jQuery || $btn.hasClass('is_animation')) return;
      if($btn.hasClass( _this.$activeClassName )) {
        $btn.removeClass( _this.$activeClassName ).addClass('is_animation');
        $btn.next().velocity('finish', true).velocity('slideUp', {
          duration: 200,
          complete:function(elm) {
            $btn.removeClass('is_animation');
          }
        });
      }
    },
    toggle: function($btn) {
      var _this = this;
      if(!$btn instanceof jQuery) return;
      if($btn.hasClass( _this.$activeClassName )) {
        this.close($btn);
      } else {
        this.open($btn);
      }
    },
    clear: function() {
      this.$btns.removeClass(  this.$activeClassName );
      this.$bodys.css({'display': ''});
    }
  },


  featureSlider: {
    $container: '',
    $target: '',
    fn: function() {
      this.$container = $('#js_m_relation_feature_slider_container');
      this.$target = $('#js_m_relation_feature_slider');
      if(this.$target.find('.m_relation_feature_list_item').length > 3) {
        this.$container.addClass('is_arrow');
      };

      if(MM.init.getWindowWidth() > 768 ) {
        this.init();
      }
    },
    init: function() {
      // console.log(this.$container);
      // if(!this.$container && !this.target ) { return; }
      var _this = this;
      this.$target.slick({
        speed: 200,
        slidesToShow: 3,
        appendArrows: _this.$container,
        prevArrow: '<div class="m_relation_feature_list_arrow is_prev"></div>',
        nextArrow: '<div class="m_relation_feature_list_arrow is_next"></div>'
      });
    },
    destroy: function() {
      this.$target.slick('unslick');
    }
  },

  // ▽ bodyのスクロールを固定する
  // ----------------------------------------
  bodyFixed: {
    scrollPos: false,

    // 固定する
    set: function() {
      if(!$.isNumeric(this.scrollPos)) {
        this.scrollPos = MM.vars.$window.scrollTop();
        var offset = Math.abs(this.scrollPos) * -1;
        MM.vars.$body.css({top: offset}).addClass('IS_BODY_FIXED');
        return true;
      }
      return false;
    },

    // 固定解除
    unset: function() {
      if($.isNumeric(this.scrollPos)) {
        MM.vars.$body.removeClass('IS_BODY_FIXED').css({top: ''});
        MM.vars.$window.scrollTop(this.scrollPos);
        this.scrollPos = false;
        return true;
      }
      return false;
    }
  },


  contentsSchreen: {
    fn: function() {
      MM.vars.$window.on('MM_SCROLL', () => {
        this.check();
      });
    },
    check: function() {
      var scroll = MM.vars.$window.scrollTop();
      if(scroll > 500) {
        this.set();
      } else {
        this.unset();
      }
    },
    set: function() {
      MM.vars.$body.addClass('VIEW_CONTENTS');
    },
    unset: function() {
      MM.vars.$body.removeClass('VIEW_CONTENTS');
    }
  },

  resize: function() {
    MM.vars.$window.on('resize', $.throttle(100, function(){
      MM.vars.$window.trigger('MM_RESIZE');
    }));
  },

  scroll: function() {
    MM.vars.$window.on('scroll', $.throttle(100, function(){
      MM.vars.$window.trigger('MM_SCROLL');
    }));
  },

  // UAの切り替わりを判定する
  matchMedia: function() {
    // メディアクエリの判定タイミング
    window.matchMedia('screen and (max-width:768px)').addListener( function(e) {
      // メディアクエリの条件を満たしているか
      if(!e.matches) {
        // 768より大きい
        MM.init.featureSlider.init();
        MM.init.navigationAchordion.clear();
      } else {
        // 768以下
        MM.init.featureSlider.destroy();
      }
    });

    // メディアクエリの判定タイミング
    window.matchMedia('screen and (max-width:640px)').addListener( function(e) {
      // メディアクエリの条件を満たしているか
      if(!e.matches) {
        // 640より大きい
        MM.init.sitemapAchordion.clear();
      } else {
        // 640以下
      }
    });
  },



  // googleMapApi
  googleMap: {
    fn: function() {
      var _this = this;
      // MAPエリアの存在チェックして、存在する場合のみ処理開始
      var map = document.getElementById('js_map');
      if(map) {
        _this.init(map, 35.589648, 139.732320, true);
      }
    },
    init: function(dom, lat, lng, grayScale) {
      var latLng = new google.maps.LatLng(lat, lng);
      var map = new google.maps.Map(
        dom,
        {
          zoom: 16,
          center: latLng,
          scrollwheel: false
        }
      );

      /// マーカー
      var markerImg = {
        url: '/_materials/images/common/parts/icon-map-pin.png',
        scaledSize : new google.maps.Size(70, 70)
      };
      var marker = new google.maps.Marker({
        position: latLng,
        map: map,
        icon: markerImg
      });

      var mapStyle = [{
        "stylers": [{ "saturation": -100 }]
      }];
      var mapType = new google.maps.StyledMapType(mapStyle);

      if(grayScale) {
        map.mapTypes.set('GrayScaleMap', mapType);
        map.setMapTypeId('GrayScaleMap');
      };
    }
  },


  // ▽ pjax遷移
  // ----------------------------------------
  pjax: function() {

    // ▽ 関数群
    // ----------------------------------------
    // 現在と同じページのリンクをクリックした場合、リロードをしない設定
    var linkClick = function() {
      var links = $('a[href]');
      var cbk = function(e) {
        MM.init.navigationSwitch.close();
        if(e.currentTarget.href === window.location.href) {
          $(window).scrollTop(0);
          e.preventDefault();
          e.stopPropagation();
        }
      };
      links.off('click.linkClick', cbk);
      links.on('click.linkClick', cbk);
    }


    /*
    * 遷移時に変更したい処理
    * デフォルトではhead内のmetaタグは変わりません。(titleタグは変わります)
    * また、barba-container内のjsは実行されないので個別に変更・実行したい処理をココに書きます。
    */
    Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, barbaContainer, newPageRawHTML) {
      var newNamespace = currentStatus.namespace;

      // 現在と同じページのリンクをクリックした場合、リロードをしない設定
      linkClick();

      if ( Barba.HistoryManager.history.length === 1 ) {  // ファーストビューチェック

        // ▽ ページ固有のJS
        // ----------------------------------------
        MM.init.ready.all();
        MM.init.ready.page(newNamespace);
        // if(newNamespace === 'this__index') {
        //   console.log('this__indexの場合にのみ処理されます。');
        // }

        return; // この時に更新は必要ありません
      } else {

        // ボディにnameSpaceと同じ名前をつける
        var oldNamespace = oldStatus.namespace;
        $('body').addClass(newNamespace).removeClass(oldNamespace);

        // jquery-pjaxから借りた
        var $newPageHead = $( '<head />' ).html(

          $.parseHTML(
            newPageRawHTML.match( /<head[^>]*>([\s\S.]*)<\/head>/i )[ 0 ],
            document,
            true
          )
        );

        // 変更したいタグ(ご自身の環境に合わせて適宜変更してください)
        var headTags = [
          "link[rel='canonical']",
          "link[rel='shortlink']",
          "link[rel='alternate']",
          "meta[name='description']",
          "meta[name='keywords']",
          "meta[property^='og']",
          "meta[name^='twitter']",
          "meta[name='robots']"
        ].join( ',' );
        $('head').find(headTags).remove(); // タグを削除する
        $newPageHead.find(headTags).appendTo('head'); // タグを追加する

        // Analyticsにヒットを送信(Google Analyticsを導入している場合)
        if (typeof ga === 'function') {
          ga('send', 'pageview');
        }

        // インラインのjs実行
        var temp = document.createElement('div');
        temp.innerHTML = newPageRawHTML;
        var script = temp.querySelector('#js_new_page_ready_script script');
        if(script != null){
          eval(script.innerHTML);
        }

        // ▽ ページ固有のJS
        // ----------------------------------------
        MM.init.ready.all();
        MM.init.ready.page(newNamespace);
      } // ファーストビューチェック
    }); // End Dispatcher

    // アニメーションの設定(フェードアウト→フェードイン)(オプション)
    // 必要ない場合は削除してOKです。
    var fadeTransition = Barba.BaseTransition.extend({
      start: function() {
        //startはトランジションが起動した直後の一番最初に呼び出される。

        //promise.allを使うと、配列で渡された処理が全て終わった後に.thenを実行。
        //この場合は.newContainerLOadingと.fadeOutが終わってから.thenが実行される。
        Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this));
      }, // End start function

      fadeOut: function() {
        return $(this.oldContainer).css({'opacity': 0});
      },

      fadeIn: function() {
        //.done()をつけることで古いbarba-containerのDOMは削除され、transitionが終了する。
        MM.vars.$window.scrollTop(0);
        this.done();

      }
    }); // End BaseTransition

    // returnに作ったトランジションを設定
    Barba.Pjax.getTransition = function() {
      return fadeTransition;
    };


    Barba.Dispatcher.on('linkClicked', function(HTMLElement, MouseEvent) {
      console.log('The user click on a link elegible for PJAX.');
      MM.init.bodyFixed.unset();
    });
    Barba.Dispatcher.on('initStateChange', function(currentStatus) {
      console.log('The link has just been changed.');
    });
    Barba.Dispatcher.on('newPageReady', function(currentStatus, prevStatus, HTMLElementContainer, newPageRawHTML) {
      console.log('The new container has been loaded and injected in the wrapper.');
    });
    Barba.Dispatcher.on('transitionCompleted', function(currentStatus, prevStatus) {
      console.log('The transition has just finished and the old Container has been removed from the DOM.');
      MM.init.ready.complete();
    });


    Barba.Pjax.Dom.wrapperId = 'l_pjax_wrapper'; // ラッパーIDの変更
    Barba.Pjax.Dom.containerClass = 'l_pjax_inner'; // コンテナclassの変更
    Barba.Pjax.start();
    Barba.Prefetch.init(); // prefetchを有効化

    Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
    Barba.Pjax.preventCheck = function(evt, element) {
      if(element){

        if (!element.getAttribute('href')) {
          return false;
        }
        // 外部リンクはtarget="_blank"に
        var site_url = location.protocol + '//' + location.host;
        if (!element.href.startsWith(site_url) && !element.href.startsWith('tel:') && !element.href.startsWith('mailto:')) {
          element.setAttribute('target','_blank');
          return false;
        }
        // アンカーリンクであり同一ページでなければbarbaを有効に
        var url = location.protocol + '//' + location.host + location.pathname;
        var extract_hash = element.href.replace(/#.*$/,"");
        if (element.href.startsWith(location.protocol + '//' + location.host)) {
          if (element.href.indexOf('#') > -1 && extract_hash != url ){
            return true;
          }
        }
        // 拡張子が該当する場合はtarget="_blank"に
        if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(element.href.toLowerCase())) {
          element.setAttribute('target','_blank');
          return false;
        }
        // 該当クラスに属していればBarbaを無効に
        var ignoreClasses = ['ab-item','custom-no-barba'];
        for (var i = 0; i < ignoreClasses.length; i++) {
          if (element.classList.contains(ignoreClasses[i])) {
            return false;
          }
        }
        if (!Barba.Pjax.originalPreventCheck(evt, element)) {
          return false;
        }
        return true;
      }
    };
  }, // pjax


  /** ページ読み込み時に処理する関数群です。
  * all の中に処理を記述するとすべてのページ読み込み時に処理を実行します。
  * complete の中に処理を記述すると遷移の完了時に処理を実行します。
  *
  * pageではswitch文の条件にをbodyに付与される `this__xxxx` と同じものをcase として設定するとそれらが処理されます。
  * 同じ処理を複数のページで使い回すこともできます。
  */
  ready: {
    all: function() {
      console.log('新しいページが読み込まれると処理されます。');
    },
    complete: function() {
      console.log('コンテンツが完全に差し替わったタイミングで発火します。');
      MM.init.googleMap.fn();
      MM.init.featureSlider.fn();
      MM.init.contentsAnchor();
    },
    page: function(key) {
      if(!key) return false;

      // 以下に個別の処理を記述
      switch(key) {
        case 'this__index':
          console.log('this__indexの場合にのみ処理されます。');

          // メインビジュアル（静止画バージョン）
          var $slider = $('.js_uq_hero_slider_item');
          if($slider.length) {
            var current = 0;
            var $sliderLength = $slider.length;

            MM.vars.herovisualStatic.forEach(function(value) {
              var $img = $('<img>').attr('src', value );
              console.log($img);
              console.log(value + 'をプリロードしました。');
            });

            function changeSlider() {
              var next = current == $sliderLength - 1 ? 0 : current + 1;
              var old = current == 0 ? $sliderLength - 1 : current - 1;

              $slider.removeClass('is_view is_fade_out');
              $slider.eq(current).removeClass('is_animation').addClass('is_view is_fade_out');
              $slider.eq(next).addClass('is_animation');

              current = next;
            }

            setTimeout(function(){
              setInterval(function(){
                changeSlider();
              }, 3000);
            }, 500);
          }


          // メインビジュアル動画のSP版用のスライダー
          var $frame           = $('.js_frame_slider');
          if($frame.length) {
            var $frameSlider     = $frame.children('.uq_hero_visual_frame_slider');
            var $frameItems      = $frameSlider.children();
            var $frameItemLength = $frameItems.length;

            $frameSlider.on('init', function(){
              $frame.addClass('is_init');
            });
            // メインビジュアルの画像をすべて読みこんだら再生
            var loadImage = function( keys, completed) {
              var length = keys.length;
              var count = 0;
              for(var i=0;  i < length; i++ ) {
                var key = keys[i];
                var image = new Image();
                image.onload = function() {
                  count++;
                  if(count == length) {
                    completed();
                  }
                };
                image.src = key;
              }
            };
            loadImage(MM.vars.herovisualImages, function() {
              $frameSlider.slick({
                speed: 200,
                autoplaySpeed: 1000,
                duration: 500,
                infinite: true,
                loop: false,
                autoplay: true,
                fade: true,
                arrows: false,
                pauseOnHover: false,
                swipe: false,
                pauseOnFocus: false,
                swipeToSlide: false,
              })
              .on('afterChange', function() {
                var $self = $(this);
                if(($frameItemLength - 1) <= $self.slick('slickCurrentSlide')){
                  $self.slick('slickSetOption', 'autoplay', false);
                }
              });
            });
          }


          // 強みのカルーセル
          var $thumbnail = $('#js_feature_thumbnail_slider');
          var $lead = $('#js_feature_lead_slider');

          $thumbnail.slick({
            speed: 200,
            variableWidth: true,
            centerMode: true,
            asNavFor: $lead,
            dots: true,
            customPaging: function(slick, index){
              return '<button class="slick-dots-button"></button>';
            },
          });

          $lead.slick({
            speed: 200,
            fade: true,
            arrows: false,
            asNavFor: $thumbnail,
            adaptiveHeight: true,
          });
          break; // this__index

        // 会社情報（オフィス）
        case 'this__company__office__index':
          console.log(OFFICE_ARRAY);

          OFFICE_ARRAY.forEach(function(value) {
            console.log(value.id);
            var dom = document.getElementById(value.id);
            var lat = value.lat;
            var lng = value.lng;


            MM.init.googleMap.init(dom, lat, lng, false);
          });


          break; // this__company__office
      } // end switch
    }
  }
}


document.addEventListener('DOMContentLoaded', function(){

  MM.init.execute();
});
